<template>
  <keep-alive :include="cachedViews">
    <component :is="name" :item-id="itemId"></component>
  </keep-alive>
</template>

<script>
import { getDefaultMap } from '@/common/cache'
import components, {mixin} from './map-control-components'
import {mapGetters} from 'vuex'
const Google = 'Google'
const Amap = 'Amap'
export default {
  name: 'mapControlTrack',
  data() {
    return {
      Google,
      Amap,
      defaultMap: Amap
    }
  },
  computed: {
    name() {
      let name = ''
      if (this.$route.path === '/online/monitor') {
        name =  `OnlineMonitor${this.defaultMap}`
        // name =  `OnlineMonitorBmapGL`
      } else if (this.$route.path === '/online/track') {
        name = `OnlineTrack${this.defaultMap}`
      } else if (this.$route.path === '/online/polygon') {
        name = `RiskPolygon${this.defaultMap}`
      }
      name = name.replace('Google2', 'Google')
      return name.replace('Amap', '') // OnlineMonitorAmap名称为OnlineMonitor
    },
    cachedViews() {
      const mapName = this.defaultMap.replace('Amap', '')
      return [
        `onlineMonitor${mapName}`,
        `onlineTrack${mapName}`,
        `riskPolygon${mapName}`
      ]
    },
    itemId() {
      return (this.$route.query.id && parseInt(this.$route.query.id, 10)) || null
    },
    ...mapGetters([
      'currentMap'
    ])
  },
  watch: {
    currentMap(val) {
      this.defaultMap = val
    }
  },
  created() {
    this.defaultMap = getDefaultMap(Google)
  },
  components: components,
  mixins: [
    mixin
  ]
}
</script>
